import React, { useState, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TokenGuard } from "../App";
import { Oval } from "react-loader-spinner"; // Import loader

const OtpVerification = () => {
  const navigate = useNavigate();
  const [otpValues, setOtpValues] = useState(["", "", "", "", ""]); // Array to store OTP values
  const [formData, setFormData] = useState({
    code: "", // Combined OTP code
  });
  const refs = useRef([]);

  const [isLoading, setIsLoading] = useState(false); // Add loading state

  const handleChange = (index, value) => {
    const newOtpValues = [...otpValues];
    newOtpValues[index] = value;
    setOtpValues(newOtpValues);

    // Combine OTP values into a single string
    const otpCode = newOtpValues.join("");
    setFormData({ ...formData, code: otpCode });

    // Move focus to the next input box if available
    if (value && index < 4) {
      refs.current[index + 1].focus();
    }
  };

  const handleSubmit = async (e) => {
    setIsLoading(true); // Set loading state to true
    e.preventDefault();
    try {
      const token = localStorage.getItem("token"); // Get token from local storage
      const response = await axios.post(
        "https://backend.inyumba.co.ke/iNyumba/auth/otp",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add token to the request headers
          },
        }
      );
      // Store the new token in local storage (if returned in the response)
      if (response.data.response.token) {
        localStorage.setItem("token", response.data.response.token);
        localStorage.setItem(
          "user",
          JSON.stringify(response.data.response.user)
        );
      }

      // Display toast message of success
      toast.success("OTP verification successful!");
      setIsLoading(false); // Set loading state to true
      // Redirect to home page or any other desired page
      navigate("/user/home"); // Navigate to the Home page
    } catch (error) {
      setIsLoading(false); // Set loading state to true
      console.error(error); // Display error message
      // Display toast message of error
      toast.error("An error occurred, please try again later");
    }
  };

  const handleResendOtp = async () => {
    setIsLoading(true); // Set loading state to true
    try {
      const token = localStorage.getItem("token"); // Get token from local storage
      await axios.post(
        "https://backend.inyumba.co.ke/iNyumba/auth/resend-otp",
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add token to the request headers
          },
        }
      );

      setIsLoading(false); // Set loading state to false

      // Display toast message of success
      toast.success("OTP resent successfully!");
    } catch (error) {
      console.error(error); // Display error message
      setIsLoading(false); // Set loading state to false
      // Display toast message of error
      toast.error("An error occurred while resending OTP");
    }
  };

  return (
    <TokenGuard>
      <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              OTP Verification
            </h2>
          </div>
          <form onSubmit={handleSubmit} className="mt-8 space-y-6">
            <div className="flex justify-between">
              {otpValues.map((value, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength="1"
                  value={value}
                  onChange={(e) => handleChange(index, e.target.value)}
                  className="appearance-none w-12 h-12 text-center border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  ref={(input) => (refs.current[index] = input)}
                />
              ))}
            </div>
            <div className="flex justify-between">
              <button
                type="submit"
                disabled={isLoading}
                className={`${
                  isLoading
                    ? "bg-gray-500"
                    : "group relative flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                } text-white py-2 px-4 rounded-md focus:outline-none`}
              >
                {isLoading ? (
                  <Oval
                    height={20}
                    width={20}
                    color="white"
                    visible={true}
                    ariaLabel="loading-indicator"
                    secondaryColor="white"
                  />
                ) : (
                  " Verify OTP"
                )}
              </button>
              <button
                onClick={handleResendOtp}
                disabled={isLoading}
                className={`${
                  isLoading
                    ? "bg-gray-500"
                    : "group relative flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                } text-white py-2 px-4 rounded-md focus:outline-none`}
              >
                {isLoading ? (
                  <Oval
                    height={20}
                    width={20}
                    color="white"
                    visible={true}
                    ariaLabel="loading-indicator"
                    secondaryColor="white"
                  />
                ) : (
                  " Resend OTP"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </TokenGuard>
  );
};

export default OtpVerification;
